.ipInput {
    display: flex;
    align-items: center;
    border: 1px solid #333;
    border-radius: 4px;
    padding: 0 4px;
    width: 150px;
}

.ipInput-field {
    width: 30px;
    height: 30px;
    text-align: center;
    border: unset;
}

.ipInput-field:focus-visible {
    outline: unset !important;
}

.ip-error {
    border-color: #a94442;
}