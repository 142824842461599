.topIcon {
    margin: 0 10px;
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.errorIcon {
    color: #f44336;
}
.warningIcon {
    color: rgb(249, 181, 40);
}
.infoIcon {
    color: #2196f3;
}

.alertContainer .MuiPaper-rounded {
    padding: 16px;
    width: 600px;
    background: #42596b;
}

.alertbanner {
    padding: 4px 8px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.alertbanner:last-child {
    margin-bottom: 0;
}

.top-right {
    display: flex;
    align-items: center;
}

.name {
    display: flex;
    flex-direction: column;
/*    justify-content: center;*/
    align-items: flex-end;
    height: 94px;
}