.SubBar {
  min-height: 41px;
  width: 100%;
  color: #fff;
  background: #004057;
  border: none;
  padding: 0px;
  margin: 0px;
}

.SubMenu {
  color: inherit;
  background: transparent;
  font-size: 14px;
  font-weight: normal;
  border: none;
  padding: 0px;
  margin: 0px;
}

a.SubMenuItem {
  min-width: 40px;
  height: 41px;
  display: inline-block;
  color: inherit;
  box-shadow: none;
  text-align: left;
  text-decoration: none;
  font-size: inherit;
  font-weight: normal;
  padding: 10px 0px;
  margin: 0px 40px 0px 0px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

a.SubMenuItem:hover {
  background: transparent;
  color: #fab529;
}

a.SubMenuItem.active {
  background: transparent;
  color: #fab529;
}
