.TopMenu {
  color: inherit;
  background: transparent;
  font-size: 14px;
  font-weight: normal;
}

a.TopMenuItem {
  min-width: 40px;
  height: 29px;
  display: inline-block;
  color: inherit;
  box-shadow: none;
  font-size: inherit;
  font-weight: normal;
  letter-spacing: 1.5px;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0px 0px 6px 0px;
  margin: 0px 40px 0px 0px;
  border-radius: 0px;
}

a.TopMenuLogout {
  float: right;
  margin: 0px;
}

a.TopMenuItem:hover {
  background: transparent;
  border-bottom: 4px solid #fab529;
}

a.TopMenuItem.active {
  background: transparent;
  border-bottom: 4px solid #fab529;
}

.top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.warningLabel {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1.1px;
}
